<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">我的短信</div>
		
		<div class="mt20">
			<a-spin :spinning="loading">
				<div class="bg-w pd30" style="width: 100%;">
					<div class="flex alcenter">
						<div class="ft14 ftw500 cl-info">剩余短信：</div>
						<div class="ft14 ftw500 cl-theme ml5">{{form.sms_num}}条</div>
						<div class="ml20">
							<a-button type="primary" @click="chooseSetmealAct()">充值</a-button>
						</div>
						
				
					</div>
					
					<div class="mt24">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">会员通知：</div>
							<div class="ml5">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="select-check-box active" v-if="form.integral_change_notice==1" @click="checkJifen()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkJifen()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">积分扣减通知</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.balance_change_notice==1" @click="checkBlance()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkBlance()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">余额消耗通知</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.order_deliver_notice_member==1" @click="checkOrderDeliverNoticeMember()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkOrderDeliverNoticeMember()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">订单发货通知用户</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.order_write_off_notice_member==1" @click="checkOrderWriteOffNoticeMember()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkOrderWriteOffNoticeMember()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">订单核销通知用户</div>
									</div>
									
									<!-- <div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.appointment_notice_member==1" @click="checkAppointmentNoticeMember()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkAppointmentNoticeMember()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">用户预约通知</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.sure_appointment==1" @click="checkSureAppointment()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkSureAppointment()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">用户预约确认通知</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.cancel_appointment==1" @click="checkCancelAppointment()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkCancelAppointment()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">预约取消通知</div>
									</div> -->
								</div>
							</div>
						</div>
<!-- 						
						<div class="flex  mt24">
							<div class="ft14 ftw500 cl-info">经营通知：</div>
							<div class="ml5">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="select-check-box active" v-if="form.new_order_notice==1" @click="checkOrder()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkOrder()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">新订单通知</div>
									</div>
									
									<div class="flex alcenter">
										<div class="select-check-box active" v-if="form.vip_balance_change_notice==1" @click="checkVipBlance()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkVipBlance()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">会员余额增加通知</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.order_notice_admin==1" @click="checkOrderNoticeAdmin()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkOrderNoticeAdmin()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">订单通知管理员</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.appointment_notice_admin==1" @click="checkAppointmentNoticeAdmin()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkAppointmentNoticeAdmin()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">新增预约通知管理员</div>
									</div>
									
									<div class="flex alcenter ml16">
										<div class="select-check-box active" v-if="form.appointment_notice_service_person==1" @click="checkAppointmentNoticeServicePerson()">
											<a-icon type="check" class="cl-w"/>
										</div>
										<div class="select-check-box" v-else @click="checkAppointmentNoticeServicePerson()">
											
										</div>
										<div class="ft14 ftw500 cl-main ml8">服务人员预约通知</div>
									</div>
								</div>
								
								<div class="mt10">
									<div class="flex alcenter">
										<div class="flex wrap alcenter">
											<div class="app-sms-mobile-item" v-for="(item,index) in form.boss_mobiles">
												<a-input v-model="form.boss_mobiles[index]"/>
												<div class="app-sms-mobile-item-action" @click="delItem(index)">
													<i class="iconfont iconbtn_close"/>
												</div>
											</div>
											<div class="ml10">
												<a-button type="link" @click="addItem()">+添加通知手机</a-button>
											</div>
										</div>
									</div>
								</div>
								
								
							</div>
						</div>
					 -->
						<div class="mt20">
							<a-button type="primary" :loading="confirmLoading" @click="save()">保存设置</a-button>
						</div>
					</div>
				</div>
			</a-spin>
		</div>
		
		<div class="mt20">
			<a-layout>
				<a-layout-header class="layout-classes-header">
					<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
						<a-menu-item key="recharge">充值记录</a-menu-item>
						<a-menu-item key="send">发送记录</a-menu-item>
					</a-menu>
				</a-layout-header>
				<a-layout-content class="layout-classes-content">
					<div v-if="selectCurrent == 'recharge'">
						<sms-recharge></sms-recharge>
					</div>
					<div v-if="selectCurrent == 'send'">
						<sms-send></sms-send>
					</div>
					
				</a-layout-content>
			</a-layout>
		</div>
		
		<div v-if="chooseSetmealLoading">
			<choose-setmeal :visible="chooseSetmealLoading" @cancel="cancelChooseSetmeal" @ok="okChooseSetmeal"></choose-setmeal>
		</div>
	</div>
</template>

<script>
	import smsRecharge from './components/sms/recharge.vue';
	import smsSend from './components/sms/send.vue';
	import chooseSetmeal from './components/sms/modal/chooseSetmeal.vue';
	export default{
		components:{
			smsRecharge,
			smsSend,
			chooseSetmeal,
		},
		data(){
			return{
				loading:false,
				confirmLoading:false,
				chooseSetmealLoading:false,
				select_id:0,
				selectCurrent:'recharge',
				form:{
					sms_num:0,
					integral_change_notice:0,
					balance_change_notice:0,
					//new_order_notice:0, 基础版不做订单通知
					//vip_balance_change_notice:0,
					//order_notice_admin:0,
					order_deliver_notice_member:0,
					order_write_off_notice_member:0,
					//appointment_notice_admin:0,
					appointment_notice_member:0,
					sure_appointment:0,
					cancel_appointment:0,
				//	appointment_notice_service_person:0,
					//boss_mobiles:[],
				},
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showSmsSetting').then(res=>{
					this.form=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveSmsSetting',{
					integral_change_notice:this.form.integral_change_notice,
					balance_change_notice:this.form.balance_change_notice,
					//new_order_notice:this.form.new_order_notice,
					//vip_balance_change_notice:this.form.vip_balance_change_notice,
					//order_notice_admin:this.form.order_notice_admin,
					order_deliver_notice_member:this.form.order_deliver_notice_member,
					order_write_off_notice_member:this.form.order_write_off_notice_member,
					//appointment_notice_admin:this.form.appointment_notice_admin,
					appointment_notice_member:this.form.appointment_notice_member,
					sure_appointment:this.form.sure_appointment,
					cancel_appointment:this.form.cancel_appointment,
				//	appointment_notice_service_person:this.form.appointment_notice_service_person,
				//	boss_mobiles:this.form.boss_mobiles,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
			
			addItem(){
				this.form.boss_mobiles.push('')
			},
			
			delItem(index){
				this.form.boss_mobiles.splice(index,1);
			},
			
			checkJifen(){
				this.form.integral_change_notice =  this.form.integral_change_notice == 1 ? 0 : 1;
			},
			checkBlance(){
				this.form.balance_change_notice =  this.form.balance_change_notice == 1 ? 0 : 1;
			},
			checkOrderDeliverNoticeMember(){
				this.form.order_deliver_notice_member =  this.form.order_deliver_notice_member == 1 ? 0 : 1;
			},
			checkOrderWriteOffNoticeMember(){
				this.form.order_write_off_notice_member =  this.form.order_write_off_notice_member == 1 ? 0 : 1;
			},
			checkAppointmentNoticeMember(){
				this.form.appointment_notice_member =  this.form.appointment_notice_member == 1 ? 0 : 1;
			},
			checkSureAppointment(){
				this.form.sure_appointment =  this.form.sure_appointment == 1 ? 0 : 1;
			},
			checkCancelAppointment(){
				this.form.cancel_appointment =  this.form.cancel_appointment == 1 ? 0 : 1;
			},
		
			checkVipBlance(){
				this.form.vip_balance_change_notice =  this.form.vip_balance_change_notice == 1 ? 0 : 1;
			},
			checkOrderNoticeAdmin(){
				this.form.order_notice_admin =  this.form.order_notice_admin == 1 ? 0 : 1;
			},
			checkAppointmentNoticeAdmin(){
				this.form.appointment_notice_admin =  this.form.appointment_notice_admin == 1 ? 0 : 1;
			},
			checkAppointmentNoticeServicePerson(){
				this.form.appointment_notice_service_person =  this.form.appointment_notice_service_person == 1 ? 0 : 1;
			},
			
			chooseSetmealAct(){
				this.chooseSetmealLoading=true;
			},
			cancelChooseSetmeal(){
				this.chooseSetmealLoading=false;
			},
			okChooseSetmeal(){
				this.chooseSetmealLoading=false;
			},
		}
	}
</script>

<style>
	.select-check-box{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 2px solid #A6AEC2;
		cursor: pointer;
	}
	
	.app-sms-alert{
		height: 40px;
		background: #FEF3D4;
		border-radius: 4px;
		line-height: 40px;
		padding: 0px 16px 0px 16px;
	}
	
	.app-sms-alert-action{
		width: 72px;
		height: 28px;
		background: #FFFFFF;
		border-radius: 4px;
		line-height: 28px;
		text-align: center;
		cursor: pointer;
		
		font-size: 12px;
		font-weight: 800;
		color: #4772FF;
	}
	
	.select-check-box.active{
		background: #4772FF;
		cursor: pointer;
		border: none;
		line-height: 20px;
		text-align: center;
	}
	
	.app-sms-mobile-item .ant-input {
	    width: 160px;
	    height: 40px;
	}
	
	.app-sms-mobile-item{
		position: relative;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	.app-sms-mobile-item-action{
		height: 40px;
		line-height: 40px;
		position: absolute;
		top:0px;
		right: 16px;
		cursor: pointer;
	}
	
	
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: 500px;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
